var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "trend_analysis" },
    [
      _c(
        "el-form",
        {
          key: "trendAnalysisForm",
          ref: "trendAnalysisForm",
          staticClass: "zwx-form",
          attrs: {
            model: _vm.trendAnalysisForm,
            "label-position": "right",
            "label-width": "80px"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            [
              _vm.ifYxh && _vm.ifSelectAgency
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "self-zone-code",
                      attrs: { "label-width": "70px", label: "所属地区：" }
                    },
                    [
                      _c("el-cascader", {
                        ref: "areaCascader",
                        staticClass: "zwx-select zwx-select-student",
                        attrs: {
                          "popper-class": "zwx-select-popper",
                          "show-all-levels": false,
                          props: { checkStrictly: true },
                          options: _vm.agencyAreaList,
                          clearable: ""
                        },
                        on: {
                          change: function($event) {
                            _vm.$refs.areaCascader.dropDownVisible = false
                          }
                        },
                        model: {
                          value: _vm.trendAnalysisForm.busZoneCode,
                          callback: function($$v) {
                            _vm.$set(_vm.trendAnalysisForm, "busZoneCode", $$v)
                          },
                          expression: "trendAnalysisForm.busZoneCode"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.ifYxh && !_vm.ifSelectAgency
                ? _c(
                    "el-form-item",
                    { attrs: { label: "所属地区：", "label-width": "70px" } },
                    [
                      _c("zwx-select-area", {
                        ref: "bizZoneArea",
                        staticClass: "cascader-area-biz-zone",
                        staticStyle: { width: "200px" },
                        attrs: {
                          zoneCode12From: _vm.zoneCode12From,
                          zoneTypeMax: "0",
                          zoneTypeMin: "4",
                          showFullName: false
                        },
                        on: {
                          loadOver: function($event) {
                            return _vm.areaIdLoadOver()
                          },
                          change: _vm.bizZoneChange
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.ifYxh
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "self-zone-code",
                      attrs: { "label-width": "70px", label: "所属地区：" }
                    },
                    [
                      _c("el-cascader", {
                        ref: "areaCascader",
                        staticClass: "zwx-select zwx-select-student",
                        attrs: {
                          "popper-class": "zwx-select-popper",
                          "show-all-levels": false,
                          props: { checkStrictly: true },
                          options: _vm.agencyAreaList,
                          clearable: ""
                        },
                        on: {
                          change: function($event) {
                            _vm.$refs.areaCascader.dropDownVisible = false
                          }
                        },
                        model: {
                          value: _vm.trendAnalysisForm.busZoneCode,
                          callback: function($$v) {
                            _vm.$set(_vm.trendAnalysisForm, "busZoneCode", $$v)
                          },
                          expression: "trendAnalysisForm.busZoneCode"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "培训班名称：", "label-width": "100px" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.trendAnalysisForm.activityName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.trendAnalysisForm,
                          "activityName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "trendAnalysisForm.activityName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "时间范围：", "label-width": "70px" } },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.$validate.noBigDate(
                        _vm.$data["trendAnalysisForm"],
                        "createTimeEnd",
                        true
                      ),
                      placeholder: "开始日期"
                    },
                    model: {
                      value: _vm.trendAnalysisForm.createTimeStart,
                      callback: function($$v) {
                        _vm.$set(_vm.trendAnalysisForm, "createTimeStart", $$v)
                      },
                      expression: "trendAnalysisForm.createTimeStart"
                    }
                  }),
                  _vm._v(" - "),
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.$validate.noSmallDate(
                        _vm.$data["trendAnalysisForm"],
                        "createTimeStart",
                        true
                      ),
                      placeholder: "结束日期"
                    },
                    model: {
                      value: _vm.trendAnalysisForm.createTimeEnd,
                      callback: function($$v) {
                        _vm.$set(_vm.trendAnalysisForm, "createTimeEnd", $$v)
                      },
                      expression: "trendAnalysisForm.createTimeEnd"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "学员类别：", "label-width": "70px" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "zwx-select zwx-select-student",
                  staticStyle: { width: "200px !important" },
                  attrs: {
                    placeholder: "请选择",
                    "popper-class": "zwx-select-popper",
                    clearable: ""
                  },
                  on: { change: val => _vm.traineeTypeChange(val) },
                  model: {
                    value: _vm.trendAnalysisForm.traineeType,
                    callback: function($$v) {
                      _vm.$set(_vm.trendAnalysisForm, "traineeType", $$v)
                    },
                    expression: "trendAnalysisForm.traineeType"
                  }
                },
                _vm._l(_vm.trendAnalysisFormExp.traineeTypeOptions, function(
                  item
                ) {
                  return _c("el-option", {
                    key: item.codeNo,
                    attrs: { label: item.codeName, value: item.codeNo }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "培训类型：", "label-width": "100px" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "zwx-select zwx-select-student",
                  staticStyle: { width: "200px !important" },
                  attrs: {
                    placeholder: "请选择",
                    "popper-class": "zwx-select-popper",
                    clearable: ""
                  },
                  on: { "visible-change": _vm.visibleChange },
                  model: {
                    value: _vm.trendAnalysisForm.trainingType,
                    callback: function($$v) {
                      _vm.$set(_vm.trendAnalysisForm, "trainingType", $$v)
                    },
                    expression: "trendAnalysisForm.trainingType"
                  }
                },
                _vm._l(
                  _vm.trendAnalysisFormExp.trainingTypeChangeOptions,
                  function(item) {
                    return _c("el-option", {
                      key: item.codeNo,
                      attrs: { label: item.codeName, value: item.codeNo }
                    })
                  }
                ),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "independent-button-row" },
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-icontext-28",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function($event) {
                  return _vm.queryTrendAnalysis()
                }
              }
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-icontext-28",
              attrs: { icon: "el-icon-download" },
              on: { click: _vm.errorReportDownload }
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "headline" },
        [
          _c("span", [_vm._v("数据趋势")]),
          _c(
            "el-form",
            {
              key: "trendAnalysisForm",
              ref: "trendAnalysisForm",
              staticClass: "zwx-form",
              attrs: {
                model: _vm.trendAnalysisForm,
                "label-position": "right",
                "label-width": "80px"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "维度汇总：", "label-width": "110px" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "zwx-select",
                      attrs: {
                        placeholder: "请选择",
                        "popper-class": "zwx-select-popper"
                      },
                      on: {
                        change: function($event) {
                          return _vm.queryTrendAnalysis()
                        }
                      },
                      model: {
                        value: _vm.trendAnalysisForm.groupAreaFormat,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.trendAnalysisForm,
                            "groupAreaFormat",
                            $$v
                          )
                        },
                        expression: "trendAnalysisForm.groupAreaFormat"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "区县", value: 1 } }),
                      _c("el-option", { attrs: { label: "街道", value: 2 } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", {
        ref: "chartColumn",
        staticStyle: { width: "100%", height: "400px", "margin-top": "20px" }
      }),
      _c(
        "div",
        {
          staticStyle: {
            width: "calc(100% - 80px)",
            margin: "0px 40px",
            "border-left": "1px solid #e1e5f4"
          }
        },
        [
          _c(
            "el-table",
            {
              staticClass: "zwx-table",
              attrs: {
                "row-key": "rid",
                data: _vm.trendAnalysisFormExp.tableList,
                border: "",
                stripe: "",
                "tooltip-effect": "light",
                "show-summary": "",
                "summary-method": _vm.getSummaries
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "x",
                  label: "区县",
                  width: "110",
                  "header-align": "center",
                  align: "left"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "enrollmentNum",
                  label: "招生人数",
                  "min-width": "120",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "finishStudyNum",
                  label: "学习完成人数",
                  "min-width": "120",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "passExamNum",
                  label: "考试通过人数",
                  "min-width": "120",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "finishStudyRatio",
                  label: "学习完成率",
                  "min-width": "120",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "passExamNumRatio",
                  label: "考试通过率",
                  "min-width": "120",
                  "header-align": "center",
                  align: "center"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }