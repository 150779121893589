<template>
  <div class="data_analysis">
    <base-tab :titles="tabTitles" v-model="activeName" @changeTab="activeChange"></base-tab>
    <!-- 区域分析 -->
    <div v-if="activeName == 1">
      <RegionAnalysis  @loading="showLoading" />
    </div>
    <!-- 趋势分析 -->
    <div v-if="activeName == 2">
      <TrendAnalysis  @loading="showLoading" />
    </div>
    <!-- 行业维度 -->
    <div v-if="activeName == 3">
      <professionAnalysis  @loading="showLoading"/>
    </div>
  </div>
</template>

<script>
import TrendAnalysis from './components/trendAnalysis'
import RegionAnalysis from './components/regionAnalysis'
import professionAnalysis from './components/professionAnalysis.vue'
export default {
  name: 'DataAnalysis',
  components: { TrendAnalysis, RegionAnalysis, professionAnalysis},
  data() {
    return {
      tabTitles: ['区域分析','趋势分析','行业分析'],
      activeName: '1',
    }
  },
  activated() { },
  mounted() { },
  methods: {
    activeChange(val) { },
    showLoading(status){
      this.$emit('loading',status)
    },
  },
}
</script>

<style lang="less" scoped></style>
