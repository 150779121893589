<template>
  <div class="trend_analysis">
    <el-form class="zwx-form" key="trendAnalysisForm" ref="trendAnalysisForm" :model="trendAnalysisForm" label-position="right" @submit.native.prevent label-width="80px">
      <div>
        <el-form-item label-width="70px" class="self-zone-code" label="所属地区：" v-if="ifYxh && ifSelectAgency">
          <el-cascader class="zwx-select zwx-select-student" popper-class="zwx-select-popper"  ref="areaCascader" :show-all-levels="false" :props="{ checkStrictly: true }" v-model="trendAnalysisForm.busZoneCode" :options="agencyAreaList" @change="$refs.areaCascader.dropDownVisible = false" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="所属地区：" label-width="70px" v-if="ifYxh && !ifSelectAgency">
          <zwx-select-area class="cascader-area-biz-zone" ref="bizZoneArea" style="width: 200px" @loadOver="areaIdLoadOver()" @change="bizZoneChange" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="false" />
        </el-form-item>

        <el-form-item label-width="70px" class="self-zone-code" label="所属地区：" v-if="!ifYxh">
          <el-cascader class="zwx-select zwx-select-student" popper-class="zwx-select-popper"  ref="areaCascader" :show-all-levels="false" :props="{ checkStrictly: true }" v-model="trendAnalysisForm.busZoneCode" :options="agencyAreaList" @change="$refs.areaCascader.dropDownVisible = false" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="培训班名称：" label-width="100px">
          <el-input class="zwx-input" v-model.trim="trendAnalysisForm.activityName" style="width: 200px !important" placeholder="请输入" clearable maxlength="50" />
        </el-form-item>
        <el-form-item label="时间范围：" label-width="70px">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 200px !important" v-model="trendAnalysisForm.createTimeStart" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noBigDate($data['trendAnalysisForm'], 'createTimeEnd', true)" placeholder="开始日期" />
          -
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 200px !important" v-model="trendAnalysisForm.createTimeEnd" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noSmallDate($data['trendAnalysisForm'], 'createTimeStart', true)" placeholder="结束日期" />
        </el-form-item>
      </div>
      <el-form-item label="学员类别：" label-width="70px">
        <el-select v-model="trendAnalysisForm.traineeType" placeholder="请选择" class="zwx-select zwx-select-student" popper-class="zwx-select-popper"  clearable @change="val => traineeTypeChange(val)" style="width: 200px !important">
          <el-option v-for="item in trendAnalysisFormExp.traineeTypeOptions" :key="item.codeNo" :label="item.codeName" :value="item.codeNo"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="培训类型：" label-width="100px">
        <el-select v-model="trendAnalysisForm.trainingType" placeholder="请选择" class="zwx-select zwx-select-student" popper-class="zwx-select-popper"  clearable style="width: 200px !important" @visible-change="visibleChange">
          <el-option v-for="item in trendAnalysisFormExp.trainingTypeChangeOptions" :key="item.codeNo" :label="item.codeName" :value="item.codeNo"></el-option>
        </el-select>
      </el-form-item>

    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryTrendAnalysis()">查询</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-download" @click="errorReportDownload">导出</el-button>
    </div>

    <div class="headline">
      <span>数据趋势</span>
      <el-form class="zwx-form" key="trendAnalysisForm" ref="trendAnalysisForm" :model="trendAnalysisForm" label-position="right" @submit.native.prevent label-width="80px">
        <el-form-item label="维度汇总：" label-width="110px">
          <el-select v-model="trendAnalysisForm.groupAreaFormat" placeholder="请选择" class="zwx-select" popper-class="zwx-select-popper"  @change="queryTrendAnalysis()">
            <el-option label="区县" :value="1"></el-option>
            <el-option label="街道" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div ref="chartColumn" style="width: 100%; height: 400px; margin-top: 20px"></div>
    <!-- 列表展示部分 -->
    <div style="width: calc(100% - 80px); margin: 0px 40px; border-left: 1px solid #e1e5f4 ">
      <el-table class="zwx-table" row-key="rid" :data="trendAnalysisFormExp.tableList" border stripe tooltip-effect="light" show-summary :summary-method="getSummaries">
        <el-table-column prop="x" label="区县" width="110" header-align="center" align="left"></el-table-column>
        <el-table-column prop="enrollmentNum" label="招生人数" min-width="120" header-align="center" align="center"></el-table-column>
        <el-table-column prop="finishStudyNum" label="学习完成人数" min-width="120" header-align="center" align="center"></el-table-column>
        <el-table-column prop="passExamNum" label="考试通过人数" min-width="120" header-align="center" align="center"></el-table-column>
        <el-table-column prop="finishStudyRatio" label="学习完成率" min-width="120" header-align="center" align="center"></el-table-column>
        <el-table-column prop="passExamNumRatio" label="考试通过率" min-width="120" header-align="center" align="center"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegionAnalysis',
  components: {},
  props: {},
  data() {
    return {
      api: this.$store.state.api,
      trendAnalysisForm: {
        busZoneCode: '',
        activityUid: null,
        traineeType: null,
        trainingType: null,
        createTimeStart: null,
        createTimeEnd: null,
        groupAreaFormat: 1,
        hasGroupTimeOrBusZoneCode: 2,
      },
      zoneCode12From: '',
      users: this.$store.state.users,
      trendAnalysisFormExp: {
        traineeTypeOptions: [],
        trainingTypeOptions: [],
        trainingTypeChangeOptions: [],
        tableList: [],
        zongjiObj: {},
      },
      chartColumn: null,
      ifYxh: false, //医学会登录
      ifSelectAgency: false, //医学会登录下是否选择机构
      agencyInfoList: [],
      agencyAreaList: [], //培训机构经营区域集合： 区-街道
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {
    //预防医学会角色地区查无锡所有,培训机构角色查本机构经营地区
    // if (this.users.fkByUnitId.unitName === '无锡预防医学会') {
    this.ifYxh = true
    // 赋值当前用户所属地区
    let config = window.top.vm.$store.state.config
    // 赋值当前用户所属地区
    this.zoneCode12From = config.empower //'320200000000'
    this.trendAnalysisForm.busZoneCode = this.users.fkByUnitId.fkByZoneId.zoneCode12
    this.$nextTick(() => {
      this.$refs.bizZoneArea.initSearch(this.zoneCode12From)
      this.$forceUpdate()
    })
    //查询所有培训机构
    // this.queryAllAgencyInfo()
    // } else {
    // this.queryAgencyInfo(this.users.fkByUnitId.uid)
    // }
    this.$simpleCodeTools.getSimpleCodeListByCodeTypeCodes('5002', data => {
      this.trendAnalysisFormExp.traineeTypeOptions = this.manyListFul(data['5002'], '')
      this.trendAnalysisFormExp.trainingTypeOptions = data['5002']
      this.queryTrainingCourse(1)
    })
    // this.queryTrendAnalysis()
  },
  methods: {
    //培训类型激活前判断是否先选择了学员类别
    //flag为true /false  下拉框出现/隐藏时触发	出现则为 true，隐藏则为 false
    visibleChange(flag) {
      if (flag && !this.trendAnalysisForm.traineeType) {
        this.$notify.info({ title: '提示', message: '请先选择学员类别' })
      }
    },
    errorReportDownload(){
      var arr=[]
      var header=['区县','招生人数','学习完成人数','考试通过人数','学习完成率','考试通过率']
      arr=[header]
      this.trendAnalysisFormExp.tableList.map(item=>{
  
        let {x,enrollmentNum,finishStudyNum,passExamNum,finishStudyRatio,passExamNumRatio}=item
        arr.push([x,enrollmentNum,finishStudyNum,passExamNum,finishStudyRatio,passExamNumRatio])
      })
     arr.push(['合计', this.trendAnalysisFormExp.zongjiObj.enrollmentNum, this.trendAnalysisFormExp.zongjiObj.finishStudyNum, this.trendAnalysisFormExp.zongjiObj.passExamNum, this.trendAnalysisFormExp.zongjiObj.finishStudyRatio, this.trendAnalysisFormExp.zongjiObj.passExamNumRatio])
      this.$system.excelAoaTo('区域分析.xlsx','sheet',arr)

    },
    areaIdLoadOver() {
      this.$nextTick(() => {
        //查询所有培训机构
        this.queryAllAgencyInfo()
        this.queryTrendAnalysis()
      })
    },
    //查询所有的培训机构
    queryAllAgencyInfo() {
      let data = {}
      if (this.users.fkByUnitId.unitType == '10340012') {
        data.uuid = this.users.fkByUnitId.uid
      }
      this.$system.get(
        this.api + '/training/platform/getTdTrainingAgencyListAll-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.agencyInfoList = data.tdTrainingAgencyList
          }
        },
        () => {
          this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },

    //查询培训机构
    queryAgencyInfo(uuid) {
      if (this.users.fkByUnitId.unitType == '10340012') {
        uuid = this.users.fkByUnitId.uid
      }
      this.$system.get(
        this.api + '/training/platform/getTdTrainingAgencyListAll-1',
        { uuid: uuid },
        true,
        true,
        data => {
          if (data.type === '00') {
            try {
              if (data.tdTrainingAgencyList == null || data.tdTrainingAgencyList.length == 0) {
                this.$system.notify('错误', '无效的培训机构', 'error')
                return
              }
              let areaAndAgencyBusZoneCodeListStr = data.tdTrainingAgencyList[0].areaAndAgencyBusZoneCodeListStr
              this.queryAgencyBusinessAreaList(areaAndAgencyBusZoneCodeListStr)
            } catch (error) {
              console.log(error, 'error')
            }
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    //查询培训机构经营地区
    queryAgencyBusinessAreaList(areaAndAgencyBusZoneCodeListStr) {
      this.$system.get(
        this.api + '/systematic/getCascaderAreaAndNextByZoneCode12Multiple-0',
        {
          zoneCode12FromListStr: areaAndAgencyBusZoneCodeListStr,
        },
        true,
        true,
        data => {
          if (data.type === '00') {
            this.agencyAreaList = data.areaAndNextList
            this.agencyAreaList.forEach(item => {
              item.value = item.zoneCode12
              item.label = item.zoneName
              item.children = item.childrenList
              item.children.forEach(itemm => {
                itemm.value = itemm.zoneCode12
                itemm.label = itemm.zoneName
              })
            })
          }
        },
        () => {
          this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },
    // 数据查询
    queryTrendAnalysis() {
      if (typeof this.trendAnalysisForm.busZoneCode != 'string') {
        if (this.trendAnalysisForm.busZoneCode.length == 1) {
          this.trendAnalysisForm.busZoneCode = this.trendAnalysisForm.busZoneCode[0]
        } else if (this.trendAnalysisForm.busZoneCode.length == 2) {
          this.trendAnalysisForm.busZoneCode = this.trendAnalysisForm.busZoneCode[1]
        } else if (this.trendAnalysisForm.busZoneCode.length == 0) {
          this.trendAnalysisForm.busZoneCode = ''
        }
      }
      let data = {
        ...this.trendAnalysisForm,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/training/platform/getMyDataStatisticsListGroup-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          try {
            if (data.type === '00') {
              let enrollmentMaxNum = 0
              if (data.enrollmentMaxNum > 4) {
                enrollmentMaxNum = 4 - (data.enrollmentMaxNum % 4) + data.enrollmentMaxNum
              } else {
                enrollmentMaxNum = 4
              }
              const option = {
                tooltip: {
                  trigger: 'axis',
                  extraCssText: 'min-width:150px;',
                  axisPointer: {
                    type: 'cross',
                    crossStyle: {
                      color: '#999',
                    },
                  },
                  formatter: params => {
                    for (let i = 0; i < params.length; i++) {
                      var relVal = params[i].name
                    }
                    for (let i = 0; i < params.length; i++) {
                      let unit = ''
                      if (params[i].seriesName == '学习完成率' || params[i].seriesName == '考试通过率') {
                        unit = '%'
                      }
                      relVal += '<br/>' + params[i].marker + params[i].seriesName + ':' + params[i].data + unit
                    }
                    return relVal
                  },
                },
                legend: {
                  data: ['招生人数', '学习完成人数', '考试通过人数', '学习完成率', '考试通过率'],
                },
                xAxis: [
                  {
                    type: 'category',
                    // name: '时间',
                    data: data.xList || [],
                    axisPointer: {
                      type: 'shadow',
                    },
                  },
                ],
                yAxis: [
                  {
                    type: 'value',
                    name: '人数',
                    min: 0,
                    max: enrollmentMaxNum,
                    interval: enrollmentMaxNum / 4,
                    axisLabel: {
                      formatter: '{value} ',
                    },
                  },
                  {
                    type: 'value',
                    name: '',
                    min: 0,
                    max: 100,
                    interval: 25,
                    axisLabel: {
                      formatter: '{value} %',
                    },
                  },
                ],
                grid: {
                  left: '60', //默认10%
                  right: '60', //默认10%
                },
                series: [
                  {
                    name: '招生人数',
                    type: 'bar',
                    data: (data.data && data.data.enrollmentNumList) || [],
                    barWidth: 40,
                  },
                  {
                    name: '学习完成人数',
                    type: 'bar',
                    data: (data.data && data.data.finishStudyNumList) || [],
                    barWidth: 40,
                  },
                  {
                    name: '考试通过人数',
                    type: 'bar',
                    data: (data.data && data.data.passExamNumList) || [],
                    barWidth: 40,
                  },
                  {
                    name: '学习完成率',
                    type: 'line',
                    yAxisIndex: 1,
                    data: (data.data && data.data.finishStudyRatioList) || [],
                    symbol: 'none',
                  },
                  {
                    name: '考试通过率',
                    type: 'line',
                    yAxisIndex: 1,
                    data: (data.data && data.data.passExamNumRatioList) || [],
                    symbol: 'none',
                  },
                ],
              }
              this.initChart(option)
              this.trendAnalysisFormExp.tableList = data.totalList && data.totalList.length > 0 ? data.totalList.slice(0, data.totalList.length - 1) : []
              this.trendAnalysisFormExp.zongjiObj = data.totalList && data.totalList.length > 0 ? data.totalList.slice(-1)[0] : {}
            }
          } catch (error) {
            console.log(error, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },
    getSummaries() {
      const sums = ['合计', this.trendAnalysisFormExp.zongjiObj.enrollmentNum, this.trendAnalysisFormExp.zongjiObj.finishStudyNum, this.trendAnalysisFormExp.zongjiObj.passExamNum, this.trendAnalysisFormExp.zongjiObj.finishStudyRatio, this.trendAnalysisFormExp.zongjiObj.passExamNumRatio]
      return sums
    },
    initChart(option) {
      this.chartColumn = this.$echarts.init(this.$refs.chartColumn)
      this.chartColumn.setOption(option)
    },
    //
    bizZoneChange() {
      this.trendAnalysisForm.busZoneCode = this.$refs.bizZoneArea.getChooseArea().zoneCode12
    },
    traineeTypeChange(data) {
      this.trendAnalysisForm.trainingType = ''
      if (data) {
        this.trendAnalysisFormExp.trainingTypeChangeOptions = this.manyListFul(this.trendAnalysisFormExp.trainingTypeOptions, data)
      } else {
        this.trendAnalysisFormExp.trainingTypeChangeOptions = []
      }
    },
    manyListFul(data, pid) {
      //递归方法 生成 json tree 数据
      var result = [],
        temp,
        obj
      data.some(e => {
        let LevelNoArr = e.codeLevelNo.split('.')
        if ((e.codeLevelNo == e.codeNo && pid == '') || LevelNoArr[LevelNoArr.length - 2] == pid) {
          obj = { ...e }
          temp = this.manyListFul(data, e.codeNo)
          if (temp.length > 0) {
            obj.children = temp
          }
          result.push(obj)
        }
      })
      return result
    },
  },
}
</script>

<style scoped lang="less">
.self-zone-code {
  .el-cascader {
    line-height: 32px !important;
  }
}
.trend_analysis {
  /deep/.el-form-item {
    .el-form-item__content {
      display: block !important;
    }
  }
}
.zwx-select-student /deep/.el-input__inner {
  width: 200px !important;
}
.headline {
  width: 100%;
  height: 42px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ebeef5 !important;
  border-bottom: 1px solid #ebeef5 !important;
  background: #ebeef5 !important;
  font-size: 14px;
}
.cascader-area-biz-zone /deep/.zwx-input {
  width: 200px !important;
}
</style>
