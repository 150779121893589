<template>
  <div>
    <base-dialog ref="refDialog" class="select-dialog" title="添加企业" :pagination="true" @currentPage="queryUnitList" :currentPage="chooseDialogForm.currentPage" :pageSize="chooseDialogForm.pageSize" :total="chooseDialogForm.total" @determine="addUnit" @cancel="close">
      <el-form ref="chooseDialogForm" class="zwx-form" :model="chooseDialogForm">
        <el-form-item label-width="70px" label="行政区划：">
          <zwx-select-area ref="areaIdArea" class="select-component" @loadOver="areaIdLoadOver()" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="false" :disabled="false" :clearable="false" />
        </el-form-item>
        <el-form-item label-width="70px" label="单位名称：">
          <el-input style="width: 260px !important" class="zwx-input" v-model.trim="chooseDialogForm.unitNameLike" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label-width="70px" label="行业类别：">
          <div>
            <el-input class="zwx-input zwx-occ-category-input" v-model="chooseDialogForm.unitIndustryName" style="width: 300px !important;" placeholder="输入行业类别" :read-only="true" @focus="msDeclareInfoCategoryDialog">
              <template slot="append" v-if="chooseDialogForm.unitIndustryName == null">
                <span><i class="el-icon-search"></i></span>
              </template>
              <template slot="append" v-else>
                <span><i class="el-icon-delete" @click="clearableUnitIndustry()"></i></span>
              </template>
            </el-input>
          </div>
        </el-form-item>

        <el-button style="margin-bottom:10px" class="zwx-button zwx-button-32" @click="queryUnitList(1)">检索</el-button>
      </el-form>
      <!-- 列表展示部分 -->
      <el-table class="zwx-table" ref="chooseUnitRef" row-key="rid" :data="chooseDialogForm.tableList" border stripe tooltip-effect="light" @selection-change="unitSelectionChange">
        <el-table-column key="selection" type="selection" reserve-selection width="55" header-align="center" align="center"></el-table-column>
        <el-table-column prop="bizFullName" label="行政区划" width="120" header-align="center" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="unitName" label="单位名称" width="350" header-align="center" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="industryName" label="行业类别" width="220" header-align="center" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="unitName" label="职业卫生管理联系人" width="200" header-align="center" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="unitName" label="联系电话" min-width="100" header-align="center" align="left" show-overflow-tooltip></el-table-column>
      </el-table>
    </base-dialog>
    <!--行业分类弹窗-->
    <industry-select ref="msDeclareComponentCategory" :ifValidateIfLeaf="false" :interfaceName="'/oh/repository'" @change="msDeclareComponentCategoryChange" />
  </div>
</template>
<script>
export default {
  name: 'UnitSelectDialog',
  props: {
    echoList: { type: Array, default: () => [] },
  },
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      zoneCode12From: undefined,
      msForm: {},
      chooseDialogForm: {
        currentPage: 1,
        pageSize: 0,
        total: 0,
        tableList: [],
        choosedUnitList: [],
        unitNameLike: '',
        unitTypeList: [],
        unitIndustryCode: null, //用人单位行业分类
        unitIndustryName: null, //用人单位行业分类
      },
      datas: {},
    }
  },
  created() {
    // this.areaIdLoadOver()
    let dataList = [
      { rid: 1, uuid: 1, bizFullName: '高淳区1', unitName: '无锡中卫信', industryName: '制造业', phone: '15621133321', concatPerson: '张三' },
      { rid: 2, uuid: 2, bizFullName: '高淳区2', unitName: '无锡中卫信', industryName: '制造业', phone: '15621133321', concatPerson: '张三' },
      { rid: 3, uuid: 3, bizFullName: '高淳区3', unitName: '无锡中卫信', industryName: '制造业', phone: '15621133321', concatPerson: '张三' },
      { rid: 4, uuid: 4, bizFullName: '高淳区4', unitName: '无锡中卫信', industryName: '制造业', phone: '15621133321', concatPerson: '张三' },
      { rid: 5, uuid: 5, bizFullName: '高淳区5', unitName: '无锡中卫信', industryName: '制造业', phone: '15621133321', concatPerson: '张三' },
    ]
    this.datas = {
      type: '00',
      resultList: dataList,
      total: 5,
      pageSize: 18,
    }
    // this.searchSuccess(data)
  },
  methods: {
    areaIdLoadOver() {
      this.queryUnitList(1)
    },
    open() {
      this.zoneCode12From = this.users.ifSuperAdmin ? '000000000000' : this.users.fkByUnitId.fkByZoneId.zoneCode12
      this.$refs.refDialog.show(true)
      this.$nextTick(() => {
        // this.$refs.areaIdArea.initSearch()
        this.searchSuccess(this.datas)
      })
    },
    /**
     * 查询单位列表
     */
    queryUnitList(currentPage) {
      this.$emit('loading', true)
      this.chooseDialogForm.currentPage = currentPage
      let data = {
        pageSize: 10,
        currentPage: this.chooseDialogForm.currentPage,
        bizZone: this.$refs.areaIdArea.getChooseArea() && this.$refs.areaIdArea.getChooseArea().zoneCode12,
        unitName: this.chooseDialogForm.unitNameLike,
        industryFullCode: this.chooseDialogForm.unitIndustryCode, //用人单位行业分类code
        operationStatusArr: [1],
      }
      this.$system.postJson(this.api + '/zky/employer/getEmployerBasicListForJg-1', data, true, true, this.searchSuccess, () => {
        this.$notify.error({ title: '错误', message: '网络连接失败' })
      })
    },
    searchSuccess(data) {
      if (data.type === '00') {
        this.chooseDialogForm.tableList = data.resultList
        this.chooseDialogForm.total = data.resultCount
        this.chooseDialogForm.pageSize = data.pageSize
        this.$nextTick(() => {
          // if (this.ifChooseUnit) {
          this.echoList.forEach(item => {
            this.chooseDialogForm.tableList.forEach(itemm => {
              if (item.uuid == itemm.uuid) {
                this.$refs.chooseUnitRef.toggleRowSelection(itemm, true)
              }
            })
          })
          // }
        })
        this.$emit('loading', false)
      } else {
        this.$notify.error({ title: '错误', message: data.mess })
      }
    },
    msDeclareComponentCategoryChange() {
      this.chooseDialogForm.unitIndustryName = this.$refs.msDeclareComponentCategory.msCategoryGet().industryName
      this.chooseDialogForm.unitIndustryCode = this.$refs.msDeclareComponentCategory.msCategoryGet().levelCodeNo
    },
    /**
     * 选择回调
     */
    unitSelectionChange(selectList) {
      console.log(selectList, 'selectList')
      this.chooseDialogForm.choosedUnitList = selectList
    },
    // 行业分类
    msDeclareInfoCategoryDialog() {
      this.$refs.msDeclareComponentCategory.show(true)
    },
    addUnit() {
      console.log(this.chooseDialogForm.tableList, ' this.chooseDialogForm.tableList')
      this.$emit(
        'selectunit',
        this.chooseDialogForm.choosedUnitList.map(item => ({ ...item }))
      )
      this.close()
    },
    close() {
      this.chooseDialogForm.choosedUnitList = []
      this.chooseDialogForm.unitNameLike = ''
      this.$refs.refDialog.show(false)
    },
  },
}
</script>

<style lang="less" scoped>
.select-dialog {
  /deep/ .el-dialog {
    width: 1200px !important;
    min-width: 1200px !important;
  }
}
</style>
